import { Navigate } from 'react-router-dom';
import { ClientsListPage, ClientDetailsPage } from '../pages/client';
import { TrustedClientsListPage } from '../pages/client/trusted-clients-list/trusted-clients-list.page';
import { IRoute } from '../types/routes.type';
import { ERoute } from '../utils';
import { EClientTabs } from '../pages/client/client-details/client-details.page';

export const ClientsRoutes: IRoute[] = [
  {
    path: `/${ERoute.TRUSTED_CLIENTS}`,
    element: <TrustedClientsListPage />,
  },
  {
    titleKey: `/${ERoute.CLIENTS}`,
    path: `/${ERoute.CLIENTS}`,
    element: <ClientsListPage />,
    isVisibleOnSidebar: true,
  },
  {
    path: `/${ERoute.CLIENTS}/:id`,
    element: <ClientDetailsPage />,
  },
  {
    path: `/${ERoute.CLIENTS}/:id/${EClientTabs.GENERAL}`,
    element: <ClientDetailsPage />,
  },
  {
    path: `/${ERoute.CLIENTS}/:id/${EClientTabs.CONTACT}`,
    element: <ClientDetailsPage />,
  },
  {
    path: `/${ERoute.CLIENTS}/:id/${EClientTabs.DEPARTMENTS}`,
    element: <ClientDetailsPage />,
  },
  {
    path: `/${ERoute.CLIENTS}/:id/${EClientTabs.PAYROLL}`,
    element: <ClientDetailsPage />,
  },
  {
    path: `/${ERoute.CLIENTS}/:id/${EClientTabs.DOCUMENTS}`,
    element: <ClientDetailsPage />,
  },
  {
    path: `/${ERoute.CLIENTS}/:id/${EClientTabs.RATES}`,
    element: <ClientDetailsPage />,
  },
  {
    path: `/${ERoute.CLIENTS}/:id/${EClientTabs.NOTES}`,
    element: <ClientDetailsPage />,
  },
];
