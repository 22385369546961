import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Client, EModuleNames, HistoryLog } from '../../backend/careo-api';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  formatDateTime,
  getItemsOfPage,
  onSelectSort,
  TFilterClient,
  uploadClientsCsvRequest,
} from '../../utils';
import { Badge, CardContainer, EModalTypes, Table } from '../ui';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../contexts/auth.context';
import { SortIcon, LeftArrowIcon, RightArrowIcon } from '../../icons';

type HistoryFormProps = {
  onCancel: () => void;
  onSuccess: () => void;
  entityId: string;
};
const numberOfItems = 8;

export const ClientHistory = ({
  onCancel,
  onSuccess,
  entityId,
}: HistoryFormProps) => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const isProfileCompleteQuery = searchParams.get('isProfileComplete');

  const { user } = useAuth();

  const [history, setHistory] = useState<(HistoryLog & { data: Client })[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [recordToDelete, setRecordToDelete] = useState<Client>();
  const [openedModalType, setOpenedModalType] = useState<EModalTypes>();
  const [filter, setFilter] = useState<TFilterClient>({
    search: '',
    regions: [],
    counties: [],
    trusts: [],
  });
  const [sort, setSort] = useState<{ key: string; value: '+' | '-' | '' }>({
    key: '',
    value: '',
  });

  const navigatePagination = (newPage: number) => {
    if (newPage <= 0 || newPage > totalPages) {
      return;
    }
    setCurrentPage(newPage);
  };

  const uploadClientsCsv = async (file: File) => {
    await uploadClientsCsvRequest(file)
      .then(() => {
        getHistory();
        toast.success('Clients uploaded successfully');
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getHistory = async () => {
    setIsLoading(true);
    await AxiosInstance.historyLogs
      .historyLogsControllerGetAllHistoryLogs({
        entityName: EModuleNames.Clients,
        entityId,
      })
      .then((response) => {
        let result = response.data as unknown as (HistoryLog & {
          data: Client;
        })[];

        setHistory(result);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
    setIsLoading(false);
  };

  const deleteClient = async () => {
    await AxiosInstance.clients
      .clientsControllerDeleteClient(recordToDelete?._id!)
      .then(() => {
        toast.success('Client removed successfully');
        getHistory();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
    setRecordToDelete(undefined);
  };

  const paginatedData = useMemo(() => {
    return getItemsOfPage(history, currentPage, numberOfItems);
  }, [history, currentPage]);

  useEffect(() => {
    getHistory();
  }, []);

  return (
    <>
      <CardContainer
        style={{
          padding: 0,
        }}
      >
        <div className="data-table-container">
          <Table className="table-fixed">
            <thead>
              <tr>
                <th
                  className="fixed-column"
                  onClick={() => onSelectSort('clientName', setSort)}
                >
                  <div>
                    <label>Date</label>
                    <SortIcon value={sort.key === 'date' ? sort.value : ''} />
                  </div>
                </th>

                <th
                  className="second-fixed-column"
                  onClick={() => onSelectSort('clientName', setSort)}
                >
                  <div>
                    <label>Action By</label>
                    <SortIcon value={sort.key === 'date' ? sort.value : ''} />
                  </div>
                </th>
                <th onClick={() => onSelectSort('clientName', setSort)}>
                  <div>
                    <label>Client Name</label>
                    <SortIcon
                      value={sort.key === 'clientName' ? sort.value : ''}
                    />
                  </div>
                </th>
                <th onClick={() => onSelectSort('firstName', setSort)}>
                  <div>
                    <label>Lead Contact</label>
                    <SortIcon
                      value={sort.key === 'firstName' ? sort.value : ''}
                    />
                  </div>
                </th>
                <th onClick={() => onSelectSort('user-firstName', setSort)}>
                  <div>
                    <label>Line Manager</label>
                    <SortIcon
                      value={sort.key === 'user-firstName' ? sort.value : ''}
                    />
                  </div>
                </th>
                <th onClick={() => onSelectSort('county', setSort)}>
                  <div>
                    <label>County</label>
                    <SortIcon value={sort.key === 'county' ? sort.value : ''} />
                  </div>
                </th>
                <th onClick={() => onSelectSort('region', setSort)}>
                  <div>
                    <label>Region</label>
                    <SortIcon value={sort.key === 'region' ? sort.value : ''} />
                  </div>
                </th>
                <th onClick={() => onSelectSort('isProfileComplete', setSort)}>
                  <div>
                    <label>Profile</label>
                    <SortIcon
                      value={sort.key === 'isProfileComplete' ? sort.value : ''}
                    />
                  </div>
                </th>
                <th onClick={() => onSelectSort('isProfileComplete', setSort)}>
                  <div>
                    <label>Vacancies</label>
                    <SortIcon
                      value={sort.key === 'isProfileComplete' ? sort.value : ''}
                    />
                  </div>
                </th>
                <th onClick={() => onSelectSort('isProfileComplete', setSort)}>
                  <div>
                    <label>Placements</label>
                    <SortIcon
                      value={sort.key === 'isProfileComplete' ? sort.value : ''}
                    />
                  </div>
                </th>
                <th onClick={() => onSelectSort('isProfileComplete', setSort)}>
                  <div>
                    <label>Timesheets</label>
                    <SortIcon
                      value={sort.key === 'isProfileComplete' ? sort.value : ''}
                    />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan={100} className="text-center">
                    Loading ...
                  </td>
                </tr>
              ) : (
                <>
                  {paginatedData.length > 0 ? (
                    <>
                      {paginatedData.map((history) => {
                        const client = history.data;
                        const {
                          extendedData: {
                            applicationCount = 0,
                            jobCount = 0,
                            timesheetCount = 0,
                          } = {
                            applicationCount: 0,
                            jobCount: 0,
                            timesheetCount: 0,
                          },
                        } = history.data as unknown as {
                          extendedData: {
                            jobCount: number;
                            applicationCount: number;
                            timesheetCount: number;
                          };
                        };

                        return (
                          <tr key={client._id}>
                            <td className="fixed-column white-bg">
                              <div className="date-item">
                                <div>
                                  <div>
                                    {formatDateTime(history.createdAt!)}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="second-fixed-column white-bg">
                              <div className="name-item">
                                <div>
                                  <div>
                                    {history.doneBy.firstName}{' '}
                                    {history.doneBy.lastName}
                                  </div>
                                  <div className="email">
                                    {history.doneBy.email}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td
                              style={
                                history.highlights.includes('clientName') ||
                                history.highlights.includes('trusted')
                                  ? { backgroundColor: '#ffecdf' }
                                  : {}
                              }
                            >
                              <div className="name-item">
                                <div>
                                  <div>{client.clientName}</div>
                                  <div className="email">
                                    {client.trusted?.name ?? '-'}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td
                              style={
                                history.highlights.includes('firstName') ||
                                history.highlights.includes('email') ||
                                history.highlights.includes('lastName')
                                  ? { backgroundColor: '#ffecdf' }
                                  : {}
                              }
                            >
                              <div className="name-item">
                                <div>
                                  <div>
                                    {client.firstName} {client.lastName}
                                  </div>
                                  <div className="email">{client.email}</div>
                                </div>
                              </div>
                            </td>
                            <td
                              style={
                                history.highlights.includes('user.firstName') ||
                                history.highlights.includes('user.lastName')
                                  ? { backgroundColor: '#ffecdf' }
                                  : {}
                              }
                            >{`${client.user?.firstName} ${client.user?.lastName}`}</td>
                            <td
                              style={
                                history.highlights.includes('address.county')
                                  ? { backgroundColor: '#ffecdf' }
                                  : {}
                              }
                            >
                              {client.address?.county || '-'}
                            </td>
                            <td
                              style={
                                history.highlights.includes('address.region')
                                  ? { backgroundColor: '#ffecdf' }
                                  : {}
                              }
                            >
                              {client.address?.region || '-'}
                            </td>
                            <td
                              style={
                                history.highlights.includes(
                                  'address.isProfileComplete',
                                )
                                  ? { backgroundColor: '#ffecdf' }
                                  : {}
                              }
                            >
                              <Badge
                                text={
                                  client.isProfileComplete
                                    ? 'Complete'
                                    : 'Incomplete'
                                }
                                type={
                                  client.isProfileComplete
                                    ? 'success'
                                    : 'danger'
                                }
                              />
                            </td>
                            <td
                              style={{
                                ...(history.highlights.includes(
                                  'extendedData.jobCount',
                                )
                                  ? { backgroundColor: '#ffecdf' }
                                  : {}),
                                textAlign: 'center',
                              }}
                            >
                              {jobCount}
                            </td>
                            <td
                              style={{
                                ...(history.highlights.includes(
                                  'extendedData.applicationCount',
                                )
                                  ? { backgroundColor: '#ffecdf' }
                                  : {}),
                                textAlign: 'center',
                              }}
                            >
                              {applicationCount}
                            </td>
                            <td
                              style={{
                                ...(history.highlights.includes(
                                  'extendedData.timesheetCount',
                                )
                                  ? { backgroundColor: '#ffecdf' }
                                  : {}),
                                textAlign: 'center',
                              }}
                            >
                              {timesheetCount}
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={100} className="text-center">
                        No item found
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </Table>
        </div>
        {paginatedData.length > 0 && (
          <div className="pagination-container">
            <div className="navigation-container">
              {/* <div
                className={`left-arrow ${currentPage === 1 && 'disabled'}`}
                onClick={() => navigatePagination(currentPage - 1)}
              >
                <LeftArrowIcon />
              </div>
              <div className="pages-list">
                {Array.from(
                  { length: totalPages },
                  (_, index) => index + 1,
                ).map((el) => (
                  <div
                    className={`page-number ${el === currentPage && 'active'}`}
                    onClick={() => navigatePagination(el)}
                  >
                    {el}
                  </div>
                ))}
              </div>
              <div
                className={`right-arrow ${
                  currentPage === totalPages && 'disabled'
                }`}
                onClick={() => navigatePagination(currentPage + 1)}
              >
                <RightArrowIcon />
              </div> */}
            </div>
            <div className="information-container">
              Showing {currentPage} to {totalPages} of {paginatedData.length}{' '}
              entries
            </div>
          </div>
        )}
      </CardContainer>
    </>
  );
};
