import React, { useState } from 'react';
import { Button, ConfirmModal, SideModal, Table } from '../ui';
import { useParams } from 'react-router-dom';
import { DeleteIcon } from '../../icons';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../utils';
import { Client, EUserRole, Rate } from '../../backend/careo-api';
import { NewRateForm } from './new-rate-form.component';
import { toast } from 'react-toastify';
import { useAuth } from '../../contexts/auth.context';

type ClientRatesProps = {
  client: Client;
  getClientDetails: () => void;
};

export const ClientRates = ({ client, getClientDetails }: ClientRatesProps) => {
  const { id } = useParams();
  const { user } = useAuth();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState<Rate>();

  const isClientBelongsToUser =
    client?.user?._id === user?._id || user?.role === EUserRole.Admin;

  const deleteRate = async () => {
    await AxiosInstance.clients
      .clientsControllerDeleteRate(id!, recordToDelete?._id!)
      .then(() => {
        toast.success('Rate removed successfully');
        getClientDetails();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
    setRecordToDelete(undefined);
  };

  return (
    <>
      <div className="info-card">
        <div className="info-card-header">
          <div className="info-card-title">Rates List</div>
          {isClientBelongsToUser && (
            <Button type="primary" onClick={() => setIsModalOpen(true)}>
              Add new rate
            </Button>
          )}
        </div>
        <hr />
        <div className="data-table-container">
          <Table>
            <thead>
              <tr>
                <th>
                  <div>
                    <label>Job Title</label>
                  </div>
                </th>
                <th>
                  <div>
                    <label>Grade</label>
                  </div>
                </th>
                <th>
                  <div>
                    <label>Charge Rate</label>
                  </div>
                </th>
                <th>
                  <div></div>
                </th>
              </tr>
            </thead>
            <tbody>
              {client.rates?.length ? (
                <>
                  {(client.rates ?? [])?.map((rate) => {
                    return (
                      <tr key={rate._id}>
                        <td>{rate?.level}</td>
                        <td>{rate?.grade}</td>
                        <td>{rate?.amount}</td>
                        <td>
                          <div className="action-item">
                            <div
                              className={`delete-icon ${
                                !isClientBelongsToUser && 'disabled'
                              }`}
                              onClick={() =>
                                isClientBelongsToUser && setRecordToDelete(rate)
                              }
                            >
                              <DeleteIcon />
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </>
              ) : (
                <tr>
                  <td colSpan={100} className="text-center">
                    No item found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>

      <SideModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title="New Rate"
      >
        <NewRateForm
          getClientDetails={getClientDetails}
          setIsOpen={setIsModalOpen}
        />
      </SideModal>

      <ConfirmModal
        isOpen={!!recordToDelete}
        title="Delete Rate"
        onNegativeBtnClick={() => setRecordToDelete(undefined)}
        onPositiveBtnClick={() => deleteRate()}
      >
        Do you want to delete{' '}
        <b>
          {recordToDelete?.level}/{recordToDelete?.grade}
        </b>{' '}
        rate ?
      </ConfirmModal>
    </>
  );
};
