import React, { useEffect, useState } from 'react';
import {
  CallIcon,
  DeleteIcon,
  EditIcon,
  LeftArrowIcon,
  MessageIcon,
  UploadIcon,
} from '../../../icons';
import {
  ATag,
  AddNewNoteCandidateForm,
  AddToVacancyForm,
  AvailabilityComponent,
  Button,
  CardContainer,
  ComplianceList,
  DragDropUpload,
  EModalTypes,
  SideModal,
  Table,
  TitlePage,
  UpdateCandidateForm,
  UserIcon,
  CandidateDetailsGeneral,
  DocumentsListComponent,
  ConfirmModal,
} from '../../../components';
import { CandidateDetailsContainer } from './candidate-details.style';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  ERoute,
  formatDateTime,
  uploadCandidateFiles,
} from '../../../utils';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Availability,
  Candidate,
  Compliance,
  EJobShift,
  EUserRole,
  HostedFile,
  Job,
  Note,
  Payroll,
  User,
} from '../../../backend/careo-api';
import { toast } from 'react-toastify';
import { useAuth } from '../../../contexts/auth.context';

export enum ECandidateTabs {
  GENERAL = 'general',
  CONTACT = 'contact',
  AVAILABILITY = 'availability',
  PAYROLL = 'payroll',
  DOCUMENTS = 'documents',
  COMPLIANCE = 'compliance',
  NOTES = 'notes',
}

const tabs = [
  ECandidateTabs.GENERAL,
  ECandidateTabs.AVAILABILITY,
  ECandidateTabs.PAYROLL,
  ECandidateTabs.DOCUMENTS,
  ECandidateTabs.COMPLIANCE,
  ECandidateTabs.NOTES,
];

export const CandidateDetailsPage = () => {
  const { user } = useAuth();
  const isAdmin = user?.role === EUserRole.Admin;
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedTab, setSelectedTab] = useState(ECandidateTabs.GENERAL);
  const [candidate, setCandidate] = useState<Candidate | null>(null);
  const [documents, setDocuments] = useState<HostedFile[]>([]);
  const [usersList, setUsersList] = useState<User[]>([]);
  const [vacanciesList, setVacanciesList] = useState<Job[]>([]);
  const [payrollsList, setPayrollsList] = useState<Payroll[]>([]);
  const [compliancesList, setCompliancesList] = useState<Compliance[]>([]);
  const [availabilities, setAvailabilities] = useState<Availability[]>([]);

  const [openedModalType, setOpenedModalType] = useState<EModalTypes>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState<Note>();

  const isPayrollBelongsToUser =
    candidate?.recruiter?._id === user?._id || user?.role === EUserRole.Admin;

  const onClickGoBack = () => {
    navigate(-1);
  };

  const handleFileChange = async (files: File[]) => {
    await uploadCandidateFiles(id!, files)
      .then(() => {
        toast.success('Documents uploaded successfully');
        getCandidateDocuments();
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const onClickDelete = (documentId: string) => {
    AxiosInstance.files
      .filesControllerDeleteFile(documentId)
      .then(() => {
        toast.success('File deleted successfully');
        getCandidateDocuments();
      })
      .catch(() => {
        toast.error('Failed to delete file');
      });
  };

  const deleteNote = (noteId: string) => {
    AxiosInstance.candidates
      .candidatesControllerDeleteNoteCandidate(id!, noteId)
      .then(() => {
        getCandidateDetails();
        setRecordToDelete(undefined);
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const getCandidateDetails = () => {
    AxiosInstance.candidates
      .candidatesControllerGetCandidate(id!)
      .then((response) => {
        setCandidate(response as unknown as Candidate);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getCandidateDocuments = () => {
    AxiosInstance.files
      .filesControllerFindCandidateDocs(id!)
      .then((result) => {
        setDocuments(result.data as HostedFile[]);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getUsers = async () => {
    AxiosInstance.users
      .usersControllerGetAllUsers()
      .then((response) => {
        setUsersList(response as unknown as User[]);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getPayrolls = async () => {
    await AxiosInstance.payrolls
      .payrollsControllerFindAll()
      .then((response) => {
        setPayrollsList(response.data as unknown as Payroll[]);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getVacancies = async () => {
    AxiosInstance.jobs
      .jobsControllerGetAllJobs()
      .then((response) => {
        setVacanciesList(response.data as unknown as Job[]);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getComplianceList = async () => {
    AxiosInstance.compliances
      .compliancesControllerGetAllCompliances({
        candidateId: id,
      })
      .then((response) => {
        setCompliancesList(
          (response.data as unknown as Compliance[]).filter(
            (el) => el.isRequired,
          ),
        );
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getAvailabilities = async () => {
    AxiosInstance.availability
      .availabilityControllerFindAllByCandidateId(id!)
      .then((response) => {
        const result = (response as unknown as Availability[]).filter(
          (el) =>
            el.availabilityTime === EJobShift.Day ||
            el.availabilityTime === EJobShift.Night,
        );
        setAvailabilities(result);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    setSelectedTab(
      (location.pathname.split('/')?.[3] as ECandidateTabs) ||
        ECandidateTabs.GENERAL,
    );
  }, [location]);

  useEffect(() => {
    getCandidateDetails();
    getUsers();
    getPayrolls();
    getVacancies();
    getCandidateDocuments();
    getComplianceList();
    getAvailabilities();
  }, []);

  if (!candidate) {
    return <></>;
  }

  return (
    <>
      <CandidateDetailsContainer>
        <div className="candidate-header">
          <div className="back-button" onClick={onClickGoBack}>
            <LeftArrowIcon />
          </div>
          <TitlePage>Detail Candidate</TitlePage>
        </div>

        <CardContainer className="page-summary-container">
          <div className="module-name">
            <UserIcon
              firstName={candidate.firstName}
              lastName={candidate.lastName}
              entity="candidate"
            />
            <div className="module-info">
              <div className="name">
                {candidate.firstName} {candidate.lastName}
              </div>
              <div className="availability">{candidate.address?.city}</div>
            </div>
          </div>
          <div className="module-contact">
            <CallIcon />
            <div>{candidate.phoneNumber}</div>
          </div>
          <div className="module-contact">
            <MessageIcon />
            <div>{candidate.email}</div>
          </div>
          <div className="actions-container">
            {setOpenedModalType && (
              <Button
                type="default"
                onClick={() => setOpenedModalType(EModalTypes.AddToVacancy)}
              >
                Add to Vacancy
              </Button>
            )}

            <Button
              type="primary"
              onClick={() => navigate(`/messages/candidate/${candidate._id}`)}
            >
              Message
            </Button>
          </div>
          {setOpenedModalType && (
            <div
              className={`edit-container edit-icon ${
                !isPayrollBelongsToUser && 'disabled'
              }`}
              onClick={() =>
                isPayrollBelongsToUser && setOpenedModalType(EModalTypes.Update)
              }
            >
              <EditIcon />
            </div>
          )}
        </CardContainer>

        <CardContainer className="candidate-information-container">
          <div className="tab-container">
            <div className="tab-items-list">
              {tabs.map((el) => (
                <ATag
                  className={`tab-item text-capitalize ${
                    el === selectedTab && 'active'
                  }`}
                  onClick={() => navigate(`/${ERoute.CANDIDATES}/${id}/${el}`)}
                >
                  {el}
                </ATag>
              ))}
            </div>
            <div className="tab-content-container">
              {selectedTab === ECandidateTabs.GENERAL && (
                <CandidateDetailsGeneral candidate={candidate} />
              )}
              {selectedTab === ECandidateTabs.AVAILABILITY && (
                <AvailabilityComponent
                  availabilities={availabilities}
                  getAvailabilities={getAvailabilities}
                />
              )}
              {selectedTab === ECandidateTabs.PAYROLL && (
                <div className="info-card">
                  <div className="info-card-title">Bank Account</div>
                  <hr />
                  <div className="info-card-content row">
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Bank Name</div>
                      <div className="info-item-data col-8">
                        {candidate.payrollProvider?.bank?.name || '-'}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Account Name</div>
                      <div className="info-item-data col-8">
                        {candidate?.payrollProvider?.bank?.accountName || '-'}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Branch</div>
                      <div className="info-item-data col-8">
                        {candidate?.payrollProvider?.address?.city || '-'}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">
                        Account Number
                      </div>
                      <div className="info-item-data col-8">
                        {candidate?.payrollProvider?.bank?.accountNumber || '-'}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Type</div>
                      <div className="info-item-data col-8">
                        {candidate?.payrollProvider?.employmentType || '-'}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Sort Code</div>
                      <div className="info-item-data col-8">
                        {candidate?.payrollProvider?.bank?.sortNumber || '-'}
                      </div>
                    </div>
                  </div>
                  <br />
                  <Table>
                    <thead>
                      <tr>
                        <th>Payroll Providers</th>
                      </tr>
                    </thead>
                    <tbody>
                      {payrollsList.map((el) => {
                        return (
                          <tr>
                            <td>
                              <div className="payroll-name-checkbox">
                                <input
                                  type="checkbox"
                                  disabled
                                  checked={
                                    el._id === candidate?.payrollProvider?._id
                                  }
                                />
                                <div>{el.payrollProviderName}</div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              )}
              {selectedTab === ECandidateTabs.DOCUMENTS && (
                <>
                  <div className="info-card">
                    <div className="info-card-title">Personal Documents</div>
                    <hr />
                    <div className="data-table-container">
                      <DocumentsListComponent
                        documents={documents}
                        onClickDelete={onClickDelete}
                        getDocuments={getCandidateDocuments}
                      />
                    </div>
                  </div>
                  <div className="info-card">
                    <DragDropUpload onUpload={handleFileChange} />
                  </div>
                </>
              )}
              {selectedTab === ECandidateTabs.COMPLIANCE && (
                <div className="info-card">
                  <div className="info-card-title">Compliances List</div>
                  <hr />
                  <div className="data-table-container">
                    <ComplianceList compliancesList={compliancesList} />
                  </div>
                </div>
              )}
              {selectedTab === ECandidateTabs.NOTES && (
                <>
                  <div className="info-card">
                    <div className="info-card-header">
                      <div className="info-card-title">Notes Info</div>
                      <Button
                        type="primary"
                        onClick={() => setIsModalOpen(true)}
                      >
                        Add Notes
                      </Button>
                    </div>
                    <hr />
                    {candidate.notes?.length ? (
                      <>
                        {candidate.notes.map((el) => (
                          <div className="info-card">
                            <div className="info-card-content w-100 notes-container">
                              <div className="left-container">
                                <div className="info-item-title">
                                  <div>
                                    {el.user.firstName} {el.user.lastName}
                                  </div>
                                  <div>{formatDateTime(el.createdAt)}</div>
                                </div>
                                <div className="info-item-data notes-container">
                                  {el.text}
                                </div>
                              </div>
                              <div className="right-container">
                                <div
                                  className={`delete-icon ${!isAdmin && 'disabled'}`}
                                  onClick={() =>
                                    isAdmin && setRecordToDelete(el)
                                  }
                                >
                                  <DeleteIcon />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <div className="w-100 text-center">No item found</div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </CardContainer>
      </CandidateDetailsContainer>

      <SideModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title="Add Note Candidate"
      >
        <AddNewNoteCandidateForm
          candidate={candidate}
          onCancel={() => setIsModalOpen(false)}
          onSuccess={() => {
            getCandidateDetails();
            setIsModalOpen(false);
          }}
        />
      </SideModal>

      <SideModal
        isOpen={openedModalType !== undefined}
        setIsOpen={() => setOpenedModalType(undefined)}
        title={
          openedModalType === EModalTypes.AddToVacancy
            ? 'Add to Vacancy'
            : 'Edit Candidate'
        }
      >
        {openedModalType === EModalTypes.AddToVacancy ? (
          <AddToVacancyForm
            onCancel={() => setOpenedModalType(undefined)}
            onSuccess={() => {
              getAvailabilities();
              setOpenedModalType(undefined);
            }}
            candidate={candidate}
            vacancies={vacanciesList}
          />
        ) : (
          <UpdateCandidateForm
            onCancel={() => setOpenedModalType(undefined)}
            onSuccess={() => {
              getCandidateDetails();
              setOpenedModalType(undefined);
            }}
            payrollsList={payrollsList}
            usersList={usersList}
            selectedCandidate={candidate}
          />
        )}
      </SideModal>

      <ConfirmModal
        isOpen={!!recordToDelete}
        title="Delete Note"
        onNegativeBtnClick={() => setRecordToDelete(undefined)}
        onPositiveBtnClick={() => deleteNote(recordToDelete?._id!)}
      >
        Do you want to delete this note ?
      </ConfirmModal>
    </>
  );
};
