import React, { useState } from 'react';
import { TitleCard, Button, Textarea, FormContainer } from '../ui';
import { Client } from '../../backend/careo-api';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../utils';
import { toast } from 'react-toastify';

type AddNewNoteClientFormProps = {
  onCancel: () => void;
  onSuccess: () => void;
  client: Client;
};

export const AddNewNoteClientForm = ({
  onCancel,
  onSuccess,
  client,
}: AddNewNoteClientFormProps) => {
  const [newNote, setNewNote] = useState('');

  const onClickSubmit = () => {
    AxiosInstance.clients
      .clientsControllerAddNoteClient(client._id, {
        text: newNote,
      })
      .then(() => {
        toast.success('Client Note updated successfully');
        onSuccess();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  return (
    <FormContainer>
      <TitleCard>Notes Info</TitleCard>
      <Textarea
        placeholder="Enter here ..."
        label=""
        required
        onChange={(e) => setNewNote(e.target.value)}
        value={newNote}
      />
      <div className="form-actions">
        <Button onClick={() => onCancel()}>Cancel</Button>
        <Button type="primary" onClick={onClickSubmit}>
          Add Note
        </Button>
      </div>
    </FormContainer>
  );
};
