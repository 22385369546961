import { ENotificationType, Job, Notification } from '../backend/careo-api';
import { ERoute } from './routes.utils';

enum ENotificationStatus {
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
  SUCCESS = 'success',
}

export type TNotificationUI = {
  _id: string;
  title: string;
  description: string;
  url: string;
  isNew: boolean;
  createdDate: Date;
  status: ENotificationStatus;
};

export const getNotificationData = (
  notification: Notification,
): TNotificationUI | null => {
  let data = notification.data as any;
  let title = '';
  let description = '';
  let url = '';
  let status: ENotificationStatus = ENotificationStatus.INFO;

  switch (notification.type) {
    case ENotificationType.CANDIDATE_REJECT_PLACEMENT:
      title = 'Placement Rejected by Candidate';
      description = `${notification?.candidate?.firstName} ${notification?.candidate?.lastName} declined the ${data?.job?.grade} placement.`;
      url = `/${ERoute.PLACEMENTS}/${data?.placement?._id}`;
      status = ENotificationStatus.WARNING;
      break;
    case ENotificationType.CANDIDATE_MATCH:
      title = 'Candidate Matched to Vacancy';
      description = `${notification?.candidate?.firstName} ${notification?.candidate?.lastName} has been matched to the ${data?.job?.specialty} vacancy.`;
      url = `/${ERoute.VACANCIES}/${data?.job?._id}/applications`;
      status = ENotificationStatus.INFO;
      break;
    case ENotificationType.COMPLIANCE_REQUIRED:
      title = 'Compliance Required';
      description = `${notification?.candidate?.firstName} ${notification?.candidate?.lastName} needs to complete compliance before the start date.`;
      url = `/${ERoute.CANDIDATES}/${notification?.candidate?._id}`;
      status = ENotificationStatus.WARNING;
      break;
    case ENotificationType.COMPLIANCE_WARNING_EXPIRATION:
      title = 'Upcoming Compliance Expiration';
      description = `Compliance for ${notification?.candidate?.firstName} ${notification?.candidate?.lastName} will expire soon.`;
      url = `/${ERoute.CANDIDATES}/${notification?.candidate?._id}`;
      status = ENotificationStatus.WARNING;
      break;
    case ENotificationType.COMPLIANCE_EXPIRED:
      title = 'Compliance Expired';
      description = `Compliance has expired for ${notification?.candidate?.firstName} ${notification?.candidate?.lastName}.`;
      url = `/${ERoute.CANDIDATES}/${notification?.candidate?._id}`;
      status = ENotificationStatus.ERROR;
      break;
    case ENotificationType.AI_VACANCY_CREATED:
      title = 'New AI Vacancy Created';
      description = `A new vacancy, ${data?.vacancy?.name}, has been created for ${data?.vacancy?.client}.`;
      url = `/${ERoute.VACANCIES}/${data?.job?._id}`;
      status = ENotificationStatus.INFO;
      break;
    case ENotificationType.COMPLIANCE_EMPLOYMENT_HISTORY_VALIDATION_IN_PROCESS:
      title = 'Employment History Validation in Process';
      description =
        'The validation process for employment history documents is ongoing.';
      url = `/${ERoute.CANDIDATES}/${notification?.candidate?._id}`;
      status = ENotificationStatus.INFO;
      break;
    case ENotificationType.COMPLIANCE_EMPLOYMENT_HISTORY_VALIDATION_SUCCESS:
      title = 'Employment History Verified';
      description =
        data?.message ||
        'Employment history documents have been successfully validated.';
      url = `/${ERoute.CANDIDATES}/${notification?.candidate?._id}`;
      status = ENotificationStatus.SUCCESS;
      break;
    case ENotificationType.COMPLIANCE_EMPLOYMENT_HISTORY_VALIDATION_FAILED:
      title = 'Employment History Validation Failed';
      description =
        data?.message ||
        'There was an error validating employment history documents. Please review and try again.';
      url = `/${ERoute.CANDIDATES}/${notification?.candidate?._id}`;
      status = ENotificationStatus.ERROR;
      break;
    case ENotificationType.COMPLIANCE_PRO_REG_VALIDATION_IN_PROCESS:
      title = 'Professional Registry Validation in Process';
      description =
        'The validation of the professional registry is currently in process.';
      url = '';
      status = ENotificationStatus.INFO;
      break;
    case ENotificationType.COMPLIANCE_PRO_REG_VALIDATION_SUCCESS:
      title = 'Professional Registry Verified';
      description =
        data?.message || 'Professional registry validation was successful.';
      url = `/${ERoute.CANDIDATES}/${notification?.candidate?._id}`;
      status = ENotificationStatus.SUCCESS;
      break;
    case ENotificationType.COMPLIANCE_PRO_REG_VALIDATION_FAILED:
      title = 'Professional Registry Validation Failed';
      description =
        data?.message ||
        `Professional registry validation failed for ${notification?.candidate?.firstName} ${notification?.candidate?.lastName}. Please review and resubmit.`;
      url = `/${ERoute.CANDIDATES}/${notification?.candidate?._id}`;
      status = ENotificationStatus.ERROR;
      break;

    // DBS CHECK
    case ENotificationType.COMPLIANCE_DBS_CHECK_VALIDATION_IN_PROCESS:
      title = 'DBS Check Validation in Progress';
      description = 'The DBS check validation is in progress.';
      url = '';
      status = ENotificationStatus.INFO;
      break;
    case ENotificationType.COMPLIANCE_DBS_CHECK_VALIDATION_SUCCESS:
      title = 'DBS Check Verified';
      description = data?.message || 'The DBS check validation was successful.';
      url = `/${ERoute.CANDIDATES}/${notification?.candidate?._id}`;
      status = ENotificationStatus.SUCCESS;
      break;
    case ENotificationType.COMPLIANCE_DBS_CHECK_VALIDATION_FAILED:
      title = 'DBS Check Validation Failed';
      description =
        data?.message ||
        'The DBS check validation failed. Please review the details or try again.';
      url = `/${ERoute.CANDIDATES}/${notification?.candidate?._id}`;
      status = ENotificationStatus.ERROR;
      break;
    default:
      title = '';
      description = '';
  }

  if (!title) {
    return null;
  }

  return {
    _id: notification._id,
    title,
    description,
    url,
    isNew: !notification.viewedDate,
    createdDate: new Date(notification.createdDate),
    status: status || ENotificationStatus.INFO,
  };
};
