import React, { useEffect, useState } from 'react';
import {
  CallIcon,
  DeleteIcon,
  LeftArrowIcon,
  MessageIcon,
} from '../../../icons';
import {
  AddressDetails,
  ATag,
  CardContainer,
  DocumentsListComponent,
  DragDropUpload,
  TitlePage,
  UserIcon,
  ClientDepartments,
  ClientRates,
  Button,
  SideModal,
  AddNewNoteClientForm,
  ConfirmModal,
} from '../../../components';
import { ClientDetailsContainer } from './client-details.style';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AxiosInstance, ERoute, formatDateTime } from '../../../utils';
import {
  Client,
  EUserRole,
  HostedFile,
  Note,
} from '../../../backend/careo-api';
import { toast } from 'react-toastify';
import { useAuth } from '../../../contexts/auth.context';

export enum EClientTabs {
  GENERAL = 'general',
  CONTACT = 'contact',
  PAYROLL = 'payroll',
  DOCUMENTS = 'documents',
  DEPARTMENTS = 'departments',
  RATES = 'rates',
  NOTES = 'notes',
}

const tabs = [
  EClientTabs.GENERAL,
  EClientTabs.CONTACT,
  EClientTabs.DEPARTMENTS,
  EClientTabs.PAYROLL,
  EClientTabs.DOCUMENTS,
  EClientTabs.RATES,
  EClientTabs.NOTES,
];

export const ClientDetailsPage = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const isAdmin = user?.role === EUserRole.Admin;

  const navigate = useNavigate();
  const location = useLocation();

  const [selectedTab, setSelectedTab] = useState(EClientTabs.GENERAL);
  const [client, setClient] = useState<Client | null>(null);
  const [clientDocuments, setClientDocuments] = useState<HostedFile[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState<Note>();

  const onClickGoBack = () => {
    navigate(-1);
  };

  const getClientDetails = () => {
    AxiosInstance.clients
      .clientsControllerGetClient(id!)
      .then((response) => {
        setClient(response as unknown as Client);
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const getClientDocuments = () => {
    AxiosInstance.files
      .filesControllerFindClientDocs(id!)
      .then((response) => {
        setClientDocuments(response as unknown as HostedFile[]);
      })
      .catch(() => {
        toast.error('Failed to get client documents');
      });
  };

  const deleteNote = (noteId: string) => {
    AxiosInstance.clients
      .clientsControllerDeleteNoteClient(id!, noteId)
      .then(() => {
        getClientDetails();
        setRecordToDelete(undefined);
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  useEffect(() => {
    setSelectedTab(
      (location.pathname.split('/')?.[3] as EClientTabs) || EClientTabs.GENERAL,
    );
  }, [location]);

  useEffect(() => {
    getClientDetails();
    getClientDocuments();
  }, []);

  if (!client) {
    return <></>;
  }

  return (
    <>
      <ClientDetailsContainer>
        <div className="client-header">
          <div className="back-button" onClick={onClickGoBack}>
            <LeftArrowIcon />
          </div>
          <TitlePage>Detail Client</TitlePage>
        </div>
        <CardContainer className="page-summary-container">
          <div className="module-name">
            <UserIcon
              firstName={client.firstName}
              lastName={client.lastName}
              entity="client"
            />
            <div className="module-info">
              <div className="name">
                {client.firstName} {client.lastName}
              </div>
              <div className="availability">{client.address?.city}</div>
            </div>
          </div>
          <div className="module-contact">
            <CallIcon />
            <div>{client.phoneNumber}</div>
          </div>
          <div className="module-contact">
            <MessageIcon />
            <div>{client.email}</div>
          </div>
        </CardContainer>
        <CardContainer className="client-information-container">
          <div className="tab-container">
            <div className="tab-items-list">
              {tabs.map((el) => (
                <ATag
                  className={`tab-item text-capitalize ${
                    el === selectedTab && 'active'
                  }`}
                  onClick={() => navigate(`/${ERoute.CLIENTS}/${id}/${el}`)}
                >
                  {el}
                </ATag>
              ))}
            </div>
            <div className="tab-content-container">
              {selectedTab === EClientTabs.GENERAL && (
                <>
                  <div className="info-card">
                    <div className="info-card-title">Client Info</div>
                    <hr />
                    <div className="info-card-content row">
                      <div className="col-md-6 row">
                        <div className="info-item-title col-4">Client Name</div>
                        <div className="info-item-data col-8">
                          {client.clientName}
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div className="info-item-title col-4">Trust</div>
                        <div className="info-item-data col-8">
                          {client.trusted?.name || '-'}
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div className="info-item-title col-4">
                          Line manager
                        </div>
                        <div className="info-item-data col-8">
                          {client.user.firstName} {client.user.lastName}
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div className="info-item-title col-4">
                          Email Address
                        </div>
                        <div className="info-item-data col-8">
                          {client.email}
                        </div>
                      </div>
                      <div className="col-md-6 row">
                        <div className="info-item-title col-4">
                          Phone Number
                        </div>
                        <div className="info-item-data col-8">
                          {client.phoneNumber}
                        </div>
                      </div>
                    </div>
                  </div>
                  <AddressDetails address={client.address} />
                </>
              )}
              {selectedTab === EClientTabs.CONTACT && (
                <div className="info-card">
                  <div className="info-card-title">Contact Details</div>
                  <hr />
                  <div className="info-card-content row">
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Title</div>
                      <div className="info-item-data col-8">
                        {client?.title}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">First Name</div>
                      <div className="info-item-data col-8">
                        {client?.firstName}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Last Name</div>
                      <div className="info-item-data col-8">
                        {client?.lastName}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Job Title</div>
                      <div className="info-item-data col-8">
                        {client?.jobTitle}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Email Address</div>
                      <div className="info-item-data col-8">
                        {client?.email}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Mobile Number</div>
                      <div className="info-item-data col-8">
                        {client?.phoneNumber}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">
                        Work Phone Number
                      </div>
                      <div className="info-item-data col-8">
                        {client?.workPhoneNumber}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Website</div>
                      <div className="info-item-data col-8">
                        {client?.website}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Fax Number</div>
                      <div className="info-item-data col-8">
                        {client?.faxNumber}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Department</div>
                      <div className="info-item-data col-8">
                        {client?.address?.county}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {selectedTab === EClientTabs.PAYROLL && (
                <div className="info-card">
                  <div className="info-card-title">Bank Account</div>
                  <hr />
                  <div className="info-card-content row">
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Bank Name</div>
                      <div className="info-item-data col-8">
                        {client?.bank?.name}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Account Name</div>
                      <div className="info-item-data col-8">
                        {client?.bank?.accountName}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Branch</div>
                      <div className="info-item-data col-8">
                        {client?.address?.city}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">
                        Account Number
                      </div>
                      <div className="info-item-data col-8">
                        {client?.bank?.accountNumber}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Type</div>
                      <div className="info-item-data col-8">- </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Sort Code</div>
                      <div className="info-item-data col-8">
                        {client?.bank?.sortNumber}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {selectedTab === EClientTabs.DOCUMENTS && (
                <>
                  <div className="info-card">
                    <div className="info-card-title">Client Documents</div>
                    <hr />
                    <DocumentsListComponent
                      documents={clientDocuments}
                      onClickDelete={() => {}}
                      getDocuments={getClientDocuments}
                    />
                  </div>
                  <div className="info-card">
                    <DragDropUpload onUpload={(files: File[]) => ({}) as any} />
                  </div>
                </>
              )}
              {selectedTab === EClientTabs.DEPARTMENTS && (
                <ClientDepartments
                  client={client}
                  getClientDetails={getClientDetails}
                />
              )}
              {selectedTab === EClientTabs.RATES && (
                <ClientRates
                  client={client}
                  getClientDetails={getClientDetails}
                />
              )}
              {selectedTab === EClientTabs.NOTES && (
                <>
                  <div className="info-card">
                    <div className="info-card-header">
                      <div className="info-card-title">Notes Info</div>
                      <Button
                        type="primary"
                        onClick={() => setIsModalOpen(true)}
                      >
                        Add Notes
                      </Button>
                    </div>
                    <hr />
                    {client.notes?.length ? (
                      <>
                        {client.notes.map((el) => (
                          <div className="info-card">
                            <div className="info-card-content w-100 notes-container">
                              <div className="left-container">
                                <div className="info-item-title">
                                  <div>
                                    {el.user.firstName} {el.user.lastName}
                                  </div>
                                  <div>{formatDateTime(el.createdAt)}</div>
                                </div>
                                <div className="info-item-data notes-container">
                                  {el.text}
                                </div>
                              </div>
                              <div className="right-container">
                                <div
                                  className={`delete-icon ${!isAdmin && 'disabled'}`}
                                  onClick={() =>
                                    isAdmin && setRecordToDelete(el)
                                  }
                                >
                                  <DeleteIcon />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <div className="w-100 text-center">No item found</div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </CardContainer>
      </ClientDetailsContainer>

      <SideModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title="Add Note Client"
      >
        <AddNewNoteClientForm
          client={client}
          onCancel={() => setIsModalOpen(false)}
          onSuccess={() => {
            getClientDetails();
            setIsModalOpen(false);
          }}
        />
      </SideModal>

      <ConfirmModal
        isOpen={!!recordToDelete}
        title="Delete Note"
        onNegativeBtnClick={() => setRecordToDelete(undefined)}
        onPositiveBtnClick={() => deleteNote(recordToDelete?._id!)}
      >
        Do you want to delete this note ?
      </ConfirmModal>
    </>
  );
};
