import React, { Dispatch, SetStateAction } from 'react';
import {
  SideModalContainer,
  SideModalContainerProps,
} from './side-modal.style';
import { RightArrowIcon } from '../../../../icons';
import { TitlePage } from '../../../ui';

type SideModalProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  title: string;
  children: any;
  onClose?: () => void;
  mode?: SideModalContainerProps['mode'];
};

export enum EModalTypes {
  Create = 'Create',
  Update = 'Update',
  NewAvailability = 'NewAvailability',
  AddToVacancy = 'AddToVacancy',
  History = 'History',
}

export const SideModal = ({
  mode = 'FORM',
  isOpen,
  setIsOpen,
  title,
  children,
  onClose = () => {},
}: SideModalProps) => {
  const _onClose = () => {
    setIsOpen(false);
    onClose();
  };

  if (!isOpen) {
    return <></>;
  }

  return (
    <SideModalContainer mode={mode}>
      <div className="back-button" onClick={_onClose}>
        <RightArrowIcon />
      </div>
      <div className="content-container">
        <TitlePage>{title}</TitlePage>
        {children}
      </div>
    </SideModalContainer>
  );
};
