import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { HeaderContainer } from './header.style';
import {
  AppIcon,
  DashboardIcon,
  DropdownIcon,
  MailIcon,
  MessageIcon,
  NewSmallNotificationRedIcon,
  NotificationIcon,
} from '../../../icons';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/auth.context';
import { SideModal } from '../../ui';
import { NewNotificationsList } from '../../notification';
import { Bounce, toast } from 'react-toastify';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  getTimeAgo,
  socket,
} from '../../../utils';
import {
  Notification,
  EWebsocketType,
  SocketEmailEvent,
  SocketMessageEvent,
} from '../../../backend/careo-api';
import { Button, UserIcon } from '../../ui';
import { isCRMApp } from '../../../environment/app.type';
import {
  TNotificationUI,
  getNotificationData,
} from '../../../utils/notifcations.utils';
import { useLocation } from 'react-router-dom';

interface HeaderProps {
  isSidebarActive: boolean;
  setIsSidebarActive: Dispatch<SetStateAction<boolean>>;
}

export const Header = ({
  isSidebarActive,
  setIsSidebarActive,
}: HeaderProps) => {
  const { logout, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notificationsList, setNotificationsList] = useState<TNotificationUI[]>(
    [],
  );
  const [isNewNotification, setIsNewNotification] = useState(false);
  const smallNotificationList = useMemo(
    () => notificationsList.slice(0, 3),
    [notificationsList],
  );

  const getNotifications = async () => {
    await AxiosInstance.notifications
      .notificationsControllerFindAll()
      .then((response) => {
        const notificationsResponse =
          response.data as unknown as Notification[];
        const result = notificationsResponse
          .map((el) => getNotificationData(el))
          .filter((el) => el?.title) as TNotificationUI[];

        setIsNewNotification(result.some((el) => el.isNew));
        setNotificationsList(result);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const openNotificationSidebar = () => {
    setIsModalOpen(true);
  };

  const onClickNotificationItem = async (notificationUI: TNotificationUI) => {
    setIsModalOpen(false);
    navigate(notificationUI.url);
    await AxiosInstance.notifications
      .notificationsControllerViewNotification(notificationUI._id)
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
    getNotifications();
  };

  const onClickHome = () => {
    navigate('/');
  };

  useEffect(() => {
    const notificationHandler = (...data: any) => {
      getNotifications();

      const receivedNotifications = data as Notification[];

      const result = receivedNotifications
        .map((el) => getNotificationData(el))
        .filter((el) => el?.title) as TNotificationUI[];

      result.map((el) => {
        toast[el.status](
          <div className="">
            <div className="notification-title">{el.title}</div>
            <div className="notification-description">{el.description}</div>
          </div>,
          {
            position: 'bottom-right',
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: 'light',
            transition: Bounce,
            onClick: () => {
              navigate(el.url);
            },
          },
        );
        return el;
      });
    };

    const emailHandler = (emailData: SocketEmailEvent) => {
      const { from, subject } = emailData;

      toast.info(
        <div className="">
          <div className="notification-title">New email received</div>
          <div className="notification-description">from : {from}</div>
        </div>,
        {
          position: 'top-right',
          autoClose: 10000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
          icon: <MailIcon />,
          onClick: () => {
            navigate('/emails');
          },
        },
      );
    };

    const messageHandler = (messageData: SocketMessageEvent) => {
      const { member } = messageData;

      if (!location.pathname.includes('/messages')) {
        toast.info(
          <div className="">
            <div className="notification-title">New message received</div>
            <div className="notification-description">
              from : {member.firstName} {member.lastName}
            </div>
          </div>,
          {
            position: 'top-right',
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: 'light',
            icon: <MessageIcon />,
            transition: Bounce,
            onClick: () => {
              navigate('/emails');
            },
          },
        );
      }
    };

    socket.on(EWebsocketType.Notification, notificationHandler);
    socket.on(EWebsocketType.Email, emailHandler);
    socket.on(EWebsocketType.Message, messageHandler);

    return () => {
      socket.off(EWebsocketType.Notification, notificationHandler);
      socket.off(EWebsocketType.Email, emailHandler);
      socket.off(EWebsocketType.Message, messageHandler);
    };
  }, [socket]);

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <>
      <HeaderContainer id="header" isSidebarActive={isSidebarActive}>
        <div className="left-header">
          {isCRMApp ? (
            <div
              className="toggle-sidebar"
              onClick={() => setIsSidebarActive((prev) => !prev)}
            >
              {isSidebarActive ? (
                <i className="bx bx-menu bx-x" id="header-toggle"></i>
              ) : (
                <i className="bx bx-menu" id="header-toggle"></i>
              )}
            </div>
          ) : (
            <div onClick={onClickHome} style={{ cursor: 'pointer' }}>
              <AppIcon />
            </div>
          )}
        </div>
        <div className="right-header">
          <div
            className="notification-modal-button"
            onClick={openNotificationSidebar}
          >
            <NotificationIcon hasNewNotification={isNewNotification} />
          </div>
          <div className="dropdown dropdown-notification">
            <div
              data-bs-toggle="dropdown"
              aria-expanded="false"
              className="notification-dropdown-button"
            >
              <NotificationIcon hasNewNotification={isNewNotification} />
            </div>

            <div className="dropdown-menu notifications-container">
              <div className="notification-header">Notification</div>
              {smallNotificationList.length ? (
                <>
                  <div className="notification-content-list">
                    {smallNotificationList.map((el) => {
                      return (
                        <div
                          className="notification-item"
                          onClick={() => onClickNotificationItem(el)}
                          key={el?._id}
                        >
                          <div className="notification-item-top">
                            <div className="notification-title">
                              {el?.title}
                            </div>
                            <div className="notification-time">
                              {getTimeAgo(el.createdDate)}
                            </div>
                          </div>
                          <div className="notification-item-bottom">
                            <div className="notification-description">
                              {el?.description}
                            </div>
                            {el.isNew && <NewSmallNotificationRedIcon />}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="notification-action">
                    <Button type="primary" onClick={openNotificationSidebar}>
                      Show All Notifications
                    </Button>
                  </div>
                </>
              ) : (
                <div>No notifications</div>
              )}
            </div>
          </div>
          {!isCRMApp && (
            <div
              className="icon-container"
              onClick={() => navigate('/settings')}
            >
              <DashboardIcon />
            </div>
          )}
          <div className="icon-container" onClick={() => navigate('/emails')}>
            <MailIcon />
          </div>
          <div className="icon-container" onClick={() => navigate('/messages')}>
            <MessageIcon />
          </div>
          <UserIcon
            firstName={user?.firstName ?? ''}
            lastName={user?.lastName ?? ''}
            entity="user"
          />
          <div className="dropdown">
            <div data-bs-toggle="dropdown" aria-expanded="false">
              <DropdownIcon />
            </div>
            <ul className="dropdown-menu">
              <li className="dropdown-item" onClick={() => logout()}>
                Logout
              </li>
            </ul>
          </div>
        </div>
      </HeaderContainer>
      <SideModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title="Notifications"
      >
        <NewNotificationsList
          setIsOpen={setIsModalOpen}
          notifications={notificationsList}
          onClickNotificationItem={onClickNotificationItem}
          getNotifications={getNotifications}
        />
      </SideModal>
    </>
  );
};
